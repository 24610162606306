import { useLanguage } from '../../Providers/LanguageContext';
import edu from '../../Assets/svg/edu.svg';
import work from '../../Assets/svg/work.svg';
import './Experience.css';
import { useEffect, useState } from 'react';
import WorkDialog from '../../Components/WorkDialog/WorkDialog';
import { Button } from '@mui/material';

const Experience = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [job, setJob] = useState({ Title: '', Company: '', Description: '' });
    const { translations, selectedLanguage, handleLanguageChange, getDataForLanguage } = useLanguage();
    const { Headers, Experience } = translations;
    if (!Headers || !Experience) {
        return null;
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const langParam = urlParams.get('lang') ?? 'pl';
        const workParam = parseInt(urlParams.get('work') || '0', 10);
        if (langParam === 'en' && selectedLanguage !== 'en') {
            handleLanguageChange(langParam);
        }
        let jobs = getDataForLanguage(langParam).Experience.Jobs
        if (workParam > 0 && workParam < Experience.Jobs.length) {
            let job = jobs[workParam] as any;
            handleOpen(job.Title, job.Company, job.DetailedDescription);
            console.log(job);
        } else {
            console.log("Nie znaleziono pracy o takim indeksie.");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOpen = (title: string, company: string, description: string) => {
        setIsOpen(true);
        setJob({ Title: title, Company: company, Description: description });
    }
    const handleClose = () => {
        setIsOpen(false);
    };
    return (
        <div className='mb-24 mt-10'>
            <div className="">
                <div className="exp-tile main-styles w-full max-w-[100vw] md:max-w-[70vw] mx-auto">
                    <h1 className="exp-h1">{Headers.Experience}</h1>
                    <div className="timeline m-auto ml-4 md:m-auto">
                        {Experience.Jobs.map((job: any, index: any) => (
                            <div key={index} className="timeline-event">
                                <span className='timeline-date ml-[10vw] md:ml-[2vw] '>{job.Date}</span>
                                <div className="timeline-dot">
                                    <img src={job.Type === 'Education' ? edu : work} alt="edu" className='m-auto my-[0.5em]' />
                                </div>
                                <div className="timeline-content p-4 md:w-[40vw]">
                                    <h3 className='exp-h3'>{job.Title}</h3>
                                    <p>{job.Company}</p>
                                    <p>{job.Description}</p>
                                    {job.Type === 'Work' ?
                                        (<div>
                                            <Button
                                                className='more-button'
                                                onClick={() => handleOpen(job.Title, job.Company, job.DetailedDescription)}
                                                disabled={job.DetailedDescription === ''}
                                                >
                                                {job.ButtonLabel}
                                            </Button>
                                        </div>)
                                        : null}
                                </div>
                            </div>
                        ))}
                        <WorkDialog
                            open={isOpen}
                            onClose={handleClose}
                            title={job.Title}
                            company={job.Company}
                            description={job.Description} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Experience;