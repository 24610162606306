import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import './WorkDialog.css';

interface WorkDialogProps {
    open: boolean;
    onClose: () => void;
    title: string;
    company: string;
    description: string;
}

const WorkDialog: React.FC<WorkDialogProps> = ({ open, onClose, title, company, description}) => {
    return (
        <Dialog open={open} onClose={onClose} classes={{ paper: 'custom-dialog' }}>
        <DialogTitle className="dialog-title">{title}</DialogTitle>
        <DialogContent>
            <Box className="dialog-company">
                <p>{company}</p>
            </Box>
        </DialogContent>
        <DialogContent>
            <Box className="dialog-content">
                <p>{description}</p>
            </Box>
        </DialogContent>
        <DialogActions className="dialog-actions">
            <Button onClick={onClose} className="dialog-button">
                X
            </Button>
        </DialogActions>
    </Dialog>
    );
};

export default WorkDialog;