import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import { LanguageProvider } from './Providers/LanguageContext';
import { useEffect, useRef, useState } from 'react';
import Sidebar from './Components/Navbar/Sidebar/Sidebar';
import NotFoundInterceptor from './Interceptors/NotFoundInterceptor';
import AppRoutes from './Components/Routes/Routes';
import useServer from './Hooks/useServer';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const { GetData } = useServer();
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      const response = GetData();
      if (response) {
        console.log(response);
      }
      hasFetchedData.current = true;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <LanguageProvider>
      <NotFoundInterceptor>
        <div className="App">
          <div id='page-container'>
            <header>
              <Navbar toggle={toggle} />
              <Sidebar isOpen={isOpen} toggle={toggle} />
            </header>
            <main className='md:px-48'>
              <AppRoutes />
            </main>
            <Footer />
          </div>
        </div>
      </NotFoundInterceptor>
    </LanguageProvider>
  );
}

export default App;
